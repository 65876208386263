import React from "react";
import {IoMdPause, IoMdPlay} from "react-icons/all";

const config = {
	cWidth: window.innerWidth,
	cHeight: 160,
	amount: 100,
	spacing: 1
}

class Player extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			playing: false
		}
	}
	
	componentDidMount() {
		if(this.props.station){
			this.changeStation(this.props.station);
		}
	}
	
	componentDidUpdate(prevProps,
		prevState,
		snapshot) {
		if (prevProps.station !== this.props.station) {
			this.changeStation(this.props.station);
		}
	}
	
	makeVis() {
		const audio = document.getElementById('audio');
		let ctx = new AudioContext();
		const analyser = ctx.createAnalyser();
		const audioSrc = ctx.createMediaElementSource(audio);
		audioSrc.connect(analyser);
		analyser.connect(ctx.destination);
		const canvas = document.getElementById('canvas');
		ctx = canvas.getContext('2d')
		const gradient = ctx.createLinearGradient(0, 0, 0, config.cHeight/2);
		gradient.addColorStop(1, '#2A2E31');
		gradient.addColorStop(0, '#212528');
		
		function renderFrame() {
			let array = new Uint8Array(analyser.frequencyBinCount);
			analyser.getByteFrequencyData(array);
			array = array.slice(0, config.amount);
			ctx.clearRect(0, 0, config.cWidth, config.cHeight);
			for (let i = 0; i < array.length; i++) {
				const value = array[i];
				ctx.fillStyle = gradient;
				const calcValue = (1/255*value)*config.cHeight;
				ctx.fillRect(i * (config.cWidth/config.amount+config.spacing), config.cHeight , config.cWidth/config.amount, -calcValue);
			}
			requestAnimationFrame(renderFrame);
		}
		
		renderFrame();
	}
	
	play() {
		this.player.play();
		this.makeVis();
	}
	
	pause() {
		this.player.pause();
		this.setState({
			playing: false
		})
	}
	
	changeStation(station) {
		this.player.innerHTML = "<source id='audio-element' src='" + station.media + "' type='audio/mpeg'/>";
		this.player.crossOrigin = "Anonymous";
		this.player.load();
		this.play();
	}
	
	render() {
		const {station} = this.props;
		const {playing} = this.state;
		return (
			<div className={"player" + (!station ? " hidden" : "")}>
				<audio
					id="audio"
					ref={ref => this.player = ref}
					onPlaying={() => {
						this.setState({playing: true})
						this.props.setLoading(false)
					}}
				/>
				<canvas id="canvas" height={config.cHeight+"px"} width={window.innerWidth+"px"}/>
				<div className="controls">
					<div className="playing">
						<div className="station" style={{backgroundColor: station.color}}>
							{ station.logo && <img src={station.logo} alt="logo"/>}
						</div>
						{/*<p>{station.name}</p>*/}
					</div>
					<div className="play-pause" onClick={() => playing ? this.pause() : this.play()}>
						{
							playing ? <IoMdPause/> : <IoMdPlay/>
						}
					</div>
				</div>
			</div>
		)
	}
}

export default Player;
