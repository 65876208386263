
export const patterns = [
	"pattern-checks-sm",
	"pattern-checks-md",
	"pattern-checks-lg",
	"pattern-checks-xl",
	"pattern-grid-sm",
	"pattern-grid-md",
	"pattern-grid-lg",
	"pattern-grid-xl",
	"pattern-dots-sm",
	"pattern-dots-md",
	"pattern-dots-lg",
	"pattern-dots-xl",
	"pattern-cross-dots-sm",
	"pattern-cross-dots-md",
	"pattern-cross-dots-lg",
	"pattern-cross-dots-xl",
	"pattern-diagonal-lines-sm",
	"pattern-diagonal-lines-md",
	"pattern-diagonal-lines-lg",
	"pattern-diagonal-lines-xl",
	"pattern-horizontal-lines-sm",
	"pattern-horizontal-lines-md",
	"pattern-horizontal-lines-lg",
	"pattern-horizontal-lines-xl",
	"pattern-vertical-lines-sm",
	"pattern-vertical-lines-md",
	"pattern-vertical-lines-lg",
	"pattern-vertical-lines-xl",
	"pattern-diagonal-stripes-sm",
	"pattern-diagonal-stripes-md",
	"pattern-diagonal-stripes-lg",
	"pattern-diagonal-stripes-xl",
	"pattern-horizontal-stripes-sm",
	"pattern-horizontal-stripes-md",
	"pattern-horizontal-stripes-lg",
	"pattern-horizontal-stripes-xl",
	"pattern-vertical-stripes-sm",
	"pattern-vertical-stripes-md",
	"pattern-vertical-stripes-lg",
	"pattern-vertical-stripes-xl",
	"pattern-triangles-sm",
	"pattern-triangles-md",
	"pattern-triangles-lg",
	"pattern-triangles-xl",
	"pattern-zigzag-sm",
	"pattern-zigzag-md",
	"pattern-zigzag-lg",
	"pattern-zigzag-xl"
];

const fixColor = (v) => {
	if (v > 255) {
		return 255;
	} else if  (v < 0) {
		return 0;
	}
	return v
}

export const lightenDarkenColor = (col, amt) => {
	let usePound = false;
	if (col[0] === "#") {
		col = col.slice(1);
		usePound = true;
	}
	const num = parseInt(col, 16);
	const r = fixColor((num >> 16) + amt);
	const b = fixColor(((num >> 8) & 0x00FF) + amt);
	const g = fixColor((num & 0x0000FF) + amt);
	return (usePound?"#":"") + (g | (b << 8) | (r << 16)).toString(16);
}
