import React from "react";
import Stations from "./Stations";
import Player from "./Player";
import '../assets/css/style.scss';

require('typeface-work-sans')

class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			station: false,
			loading: false
		}
	}
	playNow(station){
		if(this.state.station !== station){
			this.setState({
				loading: true,
				station: station
			})
		}
		console.log(station);
		document.title = station.name.toUpperCase()+" - RockMedia Radio Stations";
	}
	render() {
		const {station, loading} = this.state;
		return (
			<div className="App">
				<Stations playNow={(station) => this.playNow(station)} setLoading={(set) => this.setState({loading: set})}/>
				<Player key={station.name} station={station} setLoading={(set) => this.setState({loading: set})}/>
				{
					loading &&
					<div className="loading">
						<div className="spinner">
							<div className="dot1"/>
							<div className="dot2"/>
						</div>
					</div>
				}
			</div>
		);
	}
}

export default App;
