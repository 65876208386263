import React from "react";
import stations from "../assets/stations.json";
import Scrollbars from "react-custom-scrollbars";
import * as helper from "../assets/helper";

class Stations extends React.Component{
	constructor(props) {
		super(props);
		this.state = {
			stations: false
		}
	}
	
	changePattern() {
		const defaultColor = "#2A2E31";
		const targets = document.getElementsByClassName("random-pattern");
		if(targets.length > 0){
			for(let i = 0; i < targets.length; i++){
				const dataColor = targets[i].parentNode.dataset.color;
				const dataColorDarken = dataColor ? helper.lightenDarkenColor(dataColor, -50) : helper.lightenDarkenColor(defaultColor, -50);
				const color = dataColor ? dataColor : defaultColor;
				const bg_color = dataColorDarken;
				const pattern = helper.patterns[Math.floor(Math.random() * helper.patterns.length)];
				targets[i].style.color = color;
				targets[i].style.backgroundColor = bg_color;
				targets[i].className = pattern + " random-pattern";
			}
		}
	}
	
	componentDidMount() {
		this.props.setLoading(true);
		stations.map(s => {
			if(s.image && s.image !== ""){
				const image = require("../assets/images/"+s.image);
				s.logo = image.default;
			}
			return true;
		})
		this.setState({
			stations: stations
		})
		this.props.setLoading(false);
	}
	
	componentDidUpdate(prevProps,
		prevState,
		snapshot) {
		
		this.changePattern();
	}
	
	render(){
		const {stations} = this.state;
		return (
				<div className="scroll-wrapper">
					<Scrollbars>
						<div className="stations">
						{
							stations && stations.map((s, i) =>
								<div className="station" style={{backgroundColor: s.color}} data-color={s.color? s.color:undefined} key={"station-"+i} onClick={() => this.props.playNow(s)}>
									<div className="random-pattern"/>
									<div className="shine"/>
									{s.title && s.title !== "" && <h3>{s.title}</h3>}
									{ s.logo ? <img src={s.logo} alt="logo"/> : <p>{s.name}</p>}
								</div>
							)
						}
						</div>
					</Scrollbars>
				</div>
		)
	}
}
export default Stations;
